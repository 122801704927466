.navbar {
  /* background-color: #1f5156; */
  background-color: #1B2430;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 20;
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 80px;
  max-width: 1500px;
}
.logo-container {
  display: flex;
  align-items: center;
  color: #17CF97;
  /* color: #ffdd40; */ 
  margin-left: 2.5rem;
  cursor: pointer;
  text-decoration: none; 
}

.nav-logo {
  width: 60px;
  height: 60px;
  margin-right: 1.0rem;
}

.nav-logo-text {
  font-weight: bold;
  font-size: 2rem;
}

 

.nav-menu {
  display: flex;
  list-style: none;
  text-align: center;
  margin-right: 2rem;
}

.nav-links {
  color: #fff;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  border-bottom: 3px solid transparent;
}
.fa-code {
  margin-left: 1rem;
}

.nav-item {
  line-height: 40px;
  margin-right: 1rem;
}

.nav-item:after {
  content: "";
  display: block;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-item:hover:after {
  width: 100%;
  background: #17CF97;
}

.nav-item .active {
  color: #17CF97;
  border: 1px solid #17CF97;
}

.nav-icon {
  display: none;
}
@media screen and (max-width: 1100px) {
  .nav-logo {
    width: 50px;
    height: 50px;
    margin-right: 0.8rem;
  }
  
  .nav-logo-text {
    font-weight: bold;
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 550px) {
  .nav-logo {
    width: 40px;
    height: 40px;
    margin-right: 0.2rem;
  }
  
  .nav-logo-text {
    font-weight: bold;
    font-size: 1.1rem;
  }
}
@media screen and (max-width: 960px) {
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1pxsolid #fff;
    position: absolute;
    top: 75px;
    left: -110%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #1b2430de;
    left: 0px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }
  .nav-item .active {
    color: #17CF97;
    border: none;
  }
  .nav-links {
    padding: 1.5rem;
    width: 100%;
    display: table;
  }

  .nav-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #17CF97;
  }
  .logo-container {
    margin-left: 1.2rem;
  }
}


