* {
  margin: 0;
  padding: 0;
}

body {
  /* background-color: #b5fac7; */
  background-color: #1f5156;


}

.pages {
  color: #b5fac7;
  text-align: center;
  /* font-size: 3.5rem; */
  /* margin-top: 10%; */
  min-height: 65vh; 
  
}