.mainContainer {
  /* background-image: url(./../../img/bg.png); */
  /* background-repeat: no-repeat;
  background-position: center;
  background-size: cover; */
  /* min-height: 100vh; */
}
.contentGrid {
  justify-content: center;
  padding: 3em 1em 0 1em;
}


.imageCard {
  margin: auto;
  max-width: 400px;
  height: 500px;
  background-color: transparent;
  box-shadow: 0px 9px 70px 0px rgba(0, 0, 0, 0.3);
  border-radius: 15px;
}

.imageCardEmpty {
  height: auto;
}
.media {
  height: 400px;
}
.grid-container {
    display: grid;
    justify-content: space-around;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 0.2fr);
    grid-column-gap: 50px;
    grid-row-gap: 5px;
  }
  
  .item {
    /* background-color: #ddd; */
    text-align: start;
    padding: 0px;
  }

  .resultHeading {
    font-size: 20px;
    color: #000000bc;
    font-weight: 700;
  }

  .resultBody {
    font-size: 18px;
    color: #000000bc ;
    font-weight: 600;
  }




.buttonGrid {
    margin: auto;
    max-width: 400px;
    margin-top: 20px;
}

.clearButton {}

@media screen and (max-width: 960px) {
  .imageCard {
    margin: auto;
    max-width: 350px;
    height: 450px;
    background-color: transparent;
    box-shadow: 0px 9px 70px 0px rgba(0, 0, 0, 0.3);
    border-radius: 15px;
  }
  
  .imageCardEmpty {
    height: auto;
  }
  .media {
    height: 350px;
  }
  .buttonGrid {
    max-width: 350px;
    margin-top: 15px;
}
}
